import {
  CreateOrderRequest,
  OrderObject,
  PaginatedResponse,
  UpdateOrderRequest,
  FindOrdersRequest,
  ConflictedOrderCategoriesObject,
  GetOrderConflictsRequest,
  RateOrderRequest,
  SendFeedbackRequest,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

export interface OrdersRequestOptions {
  // Id of the restaurant
  restaurantId?: number;
}

/**
 * Encapsulate all the requests related to the Orders.
 * Depends on a restaurantId.
 * The campaignId is unpredictable and is provided by the restaurants service.
 * @see {OrderObject}
 */
export class OrdersService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(protected readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
      withCredentials: true,
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  /**
   * Find Orders.
   *
   * @param paginationOptions Pagination.
   * @returns Order list.
   */
  async findOrders(paginationOptions: FindOrdersRequest): Promise<PaginatedResponse<OrderObject>> {
    return this.axiosInstance
      .get(`/orders`, {
        params: paginationOptions,
      })
      .then(response => response.data);
  }

  /**
   * Create a new Order.
   *
   * @param createOrderData Data for the Order creation.
   * @returns Created Order.
   */
  async createOrder(createOrderData: CreateOrderRequest): Promise<OrderObject> {
    return this.axiosInstance.post(`/orders`, createOrderData).then(response => response.data);
  }

  /**
   * Update an Order by its id.
   *
   * @param orderId Id of the Order to update.
   * @param updateOrderData New data for the Order.
   * @returns Updated Order.
   */
  async updateOrder(orderId: number, updateOrderData: UpdateOrderRequest): Promise<OrderObject> {
    return this.axiosInstance
      .patch(`/orders/${orderId}`, updateOrderData)
      .then(response => response.data);
  }

  /**
   * Get an Order by its id.
   *
   * @param orderId Id of the Order.
   * @returns Requested Order.
   */
  async getOrder(orderId: number): Promise<OrderObject> {
    return this.axiosInstance.get(`/orders/${orderId}`).then(response => response.data);
  }

  /**
   * Find potential conflicts in an Order.
   *
   * @param getOrderConflictsData Data to find conflicts.
   * @returns Found conflicts, by category.
   */
  async findConflicts(
    getOrderConflictsData: GetOrderConflictsRequest,
  ): Promise<ConflictedOrderCategoriesObject> {
    return this.axiosInstance
      .post(`/orders/find-conflicts`, getOrderConflictsData)
      .then(response => response.data);
  }

  /**
   * Cancel an Order.
   *
   * @param orderId Id of the Order to cancel.
   */
  async cancelOrder(orderId: number): Promise<void> {
    return this.axiosInstance.patch(`/orders/${orderId}/cancel`).then(response => response.data);
  }

  /**
   * Rate an Order.
   *
   * @param orderId Id of the Order to rate.
   * @param rateOrderData Data to rate Order.
   * @returns Rated Order.
   */
  async rateOrder(orderId: number, rateOrderData: RateOrderRequest): Promise<OrderObject> {
    return this.axiosInstance
      .patch(`/orders/${orderId}/rate`, rateOrderData)
      .then(response => response.data);
  }

  /**
   * Give a feedback on an Order.
   *
   * @param orderId Id of the Order to rate.
   * @param sendFeedbackData Data related to the Order.
   */
  async sendFeedback(orderId: number, captchaToken: string, sendFeedbackData: SendFeedbackRequest): Promise<void> {
    return this.axiosInstance
      .post(`/orders/${orderId}/feedback`, {
        ...sendFeedbackData,
      },
      {
        headers: {
          recaptcha: captchaToken,
        }
      })
      .then(response => response.data);
  }
}
