import { IsNumber, IsPositive } from 'class-validator';

export class CreateStripePaymentRequest {
  /**
   * Order related to this payment.
   *
   * @type {number}
   * @memberof CreatePaymentRequest
   */
  @IsNumber()
  @IsPositive()
  orderId: number;

  // /**
  //  * Id of the payment method.
  //  *
  //  * @type {string}
  //  * @memberof CreateStripePaymentRequest
  //  */
  // paymentMethodId: string;
}
