import { Type } from 'class-transformer';

import { RestaurantAddressObject } from './restaurant-address.object';
import { RestaurantGeolocationObject } from './restaurant-geolocation.object';
import { RestaurantOpeningHoursObject } from './restaurant-opening-hours.object';
import { RestaurantOutageProductsObject } from './restaurant-outage-products.object';
import { RestaurantSettingsObject } from './restaurant-settings.object';

/**
 * Describe a restaurant of a campany.
 */
export class RestaurantObject {
  // Id of the restaurant
  @Type(() => Number)
  id: number;

  // Id of the store in the POS
  @Type(() => Number)
  posStoreId: number;

  // Slug of the restaurant
  slug: string;

  // Name of the restaurant
  name: string;

  /**
   * adress of the restaurant
   */
  address: RestaurantAddressObject;

  /**
   * timezone of the restaurant
   */
  timeZone: string;

  /**
   * currency used in the restaurant
   */
  currency: string;

  /**
   * location of the restaurant
   */
  @Type(() => RestaurantGeolocationObject)
  geolocation: RestaurantGeolocationObject;

  /**
   * information of the restaurant
   */
  information: string;

  /**
   * preview of the maps of the restaurant
   */
  previewUrl: string;

  /**
   * boolean to say if the restaurant is open or not
   */
  isOpen: boolean;

  /**
   * opening hours of the restaurant
   */
  @Type(() => RestaurantOpeningHoursObject)
  openingHours: RestaurantOpeningHoursObject[];

  /**
   * Current campaign id of the restaurant.
   */
  currentCampaignId?: number;

  /**
   * location of the restaurant
   */
  @Type(() => RestaurantSettingsObject)
  settings: RestaurantSettingsObject;

  /**
   * Name of the root navigation
   */
  rootNavigation: string;

  /**
   * Define which is the valid price for the restaurant
   */
  @Type(() => Number)
  contextId: number;

  /**
   * Define if the user needs to enter a password to access ordering or not
   */
  authenticationRequired: boolean;

  /**
   * List of all outages products linked at this restaurant
   */
  outageProducts: RestaurantOutageProductsObject[];

  /**
   * Array of category ids to display on the home page
   */
  productCategories?: number[];
}
