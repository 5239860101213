import { Type } from 'class-transformer';

import { CustomerDetailsObject } from './customer-details.object';
import { OrderItemObject } from './order-item.object';

/**
 * State of an order
 */
export enum OrderState {
  /**
   * 1. FIRST state step
   * Order has been created in our DB, but no payment has been confirmed.
   * When it can happen: As soon as the user clicks on the Pay button on the checkout page.
   */
  CREATED = 'CREATED',
  /**
   * 2. SECOND state step
   * Payment reservation has been done with the gateway.
   * When it can happen: Just after creating order and before display order placed success.
   */
  VALIDATED = 'VALIDATED',
  /**
   * 3. THIRD state step
   * Order injected into POS.
   * When it can happen: if order state is VALIDATED and as soon as the retrieval date minus injection delay is achieved, the order should be injected into the POS.
   */
  SENT = 'SENT',
  /**
   * 4. FOURTH state step
   * The payment has been captured (closed/confirmed) in the gateway.
   * When it can happen: if order state is SENT.
   */
  PAID = 'PAID',
  /**
   * Order has been finalized, a Payment reservation has been done, but some product/parts conflict has been found.
   */
  CONFLICTED = 'CONFLICTED',
  /**
   * Order was conflicted, payment has been cancelled, waiting for a new payment.
   */
  WAITING = 'WAITING',
  /**
   * Order cancelled by user or because of a conflict found before a payment was confirmed.
   * The user only can cancel when the order is not injected into the POS yet.
   */
  CANCELLED = 'CANCELLED',
  /**
   * Order was not injected into POS.
   * When it can happen: (CREATED && old order) OR (VALIDATED && currentDate > orderRetrievalDate && too many attempts were made).
   */
  INJECTION_ERROR = 'INJECTION_ERROR',
  /**
   * Payment issue while trying to confirm the payment with the gateway.
   * When it can happen: SENT > PAID && too many attempts were made
   */
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

/**
 * Represent an order.
 */
export class OrderObject {
  /**
   * Id of the order.
   */
  id: number;

  /**
   * Id of the restaurant holding the order.
   *
   * @type {number}
   * @memberof OrderObject
   */
  restaurantId: number;

  /**
   * Id of the campaign related to this order
   *
   * @type {number}
   * @memberof OrderObject
   */
  campaignId: number;

  /**
   * Id of the account holding the order.
   *
   * @type {number}
   * @memberof OrderObject
   */
  accountId: number;

  /**
   * Price of the order without taxes.
   *
   * @type {number}
   * @memberof OrderObject
   */
  price: number;

  /**
   * Taxes of the order.
   *
   * @type {number}
   * @memberof OrderObject
   */
  taxes: number;

  /**
   * Items in the order.
   *
   * @type {OrderItemObject}
   * @memberof OrderObject
   */
  @Type(() => OrderItemObject)
  items: OrderItemObject[];

  /**
   * Hold the state of the order.
   *
   * @type {OrderState}
   * @memberof OrderObject
   */
  state: OrderState;

  /**
   * Hold the number of attempts to process the order.
   *
   * @type {number}
   * @memberof OrderObject
   */
  attempts: number;

  /**
   * Retrieval date of the order.
   *
   * @type {Date}
   * @memberof OrderObject
   */
  retrievalDate: Date;

  /**
   * If of the order on the POS.
   *
   * @type {number}
   * @memberof OrderObject
   */
  posId: number;

  /**
   * Rating of the order, between 0 and 5 included.
   *
   * @type {number}
   * @memberof OrderObject
   */
  rating: number;

  /**
   * Date of Order creation.
   *
   * @type {Date}
   * @memberof OrderObject
   */
  createdAt: Date;

  /**
   * Date of Order update.
   *
   * @type {Date}
   * @memberof OrderObject
   */
  updatedAt: Date;

  /**
   * Date of order injection into the POS.
   *
   * @type {Date}
   * @memberof OrderObject
   */
  injectedAt: Date;

  /**
   * Date of sent feedback email
   *
   * @type {Date}
   * @memberof OrderObject
   */
  reportedAt: Date;

  /**
   * Date of report
   *
   * @type {Date}
   * @memberof OrderObject
   */
  feedbackedAt: Date;

  /**
   * Details of the customers
   * @type {CustomerDetailsObject}
   * @memberof OrderObject
   */
  customerDetails: CustomerDetailsObject;
  /**
   * Is the order is created in MOP or not
   *
   * @type {boolean}
   * @memberof OrderObject
   */
  isPredictOrder: boolean;
  /**
   * Predict sessionId if the order is created in MOP
   *
   * @type {string}
   * @memberof OrderObject
   */
  sessionId?: string;
  /**
   * Is the user on his way to pick up is order or not
   *
   * @type {boolean}
   * @memberof OrderObject
   */
  isOnMyWay?: boolean;
  /**
   * the estimated time of arrival of the user on MOP
   *
   * @type {number}
   * @memberof OrderObject
   */
  estimatedTimeOfArrival?: number;
  /**
   * Next interval check for user arrival on MOP
   *
   * @type {number}
   * @memberof OrderObject
   */
  nextETACheck?: number;
  /**
   * user's pickup mode
   *
   * @type {string}
   * @memberof OrderObject
   */
  pickupMode?: string;
}
